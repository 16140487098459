import React, { useState, useEffect } from "react"

import { useLocation } from "@reach/router"
import { navigate, Link } from "gatsby"

// React-Bootstrap
import { Accordion, OverlayTrigger } from "react-bootstrap"

// Localized Data
import navbarData from "../../../localized/navbarLocalized.json"
import buttonsData from "../../../localized/buttonsLocalized.json"

//Redux
import { useDispatch } from "react-redux"
import { changeLanguage } from "../../../redux/actions/changeLanguageAction"
import languageIcon from "../../../assets/company/language-icon-solid.svg"

const Navbar = () => {
  const dispatch = useDispatch()
  // Current language
  const currentLanguage = typeof window !== "undefined" ? localStorage.getItem("language") : null
  const [companyLanguage, setCompanyLanguage] = useState({
    language: "Türkçe",
    key: "tr",
  })

  const [languages, setLanguages] = useState([
    { language: "Türkçe", key: "tr" },
    { language: "English", key: "en" },
  ])
  // const dispatch = useDispatch();

  // Changing language
  const changeAppLanguage = (languageAbbreviation, stateToClose) => {
    // Setting the language, closing the dropdown or navigation menu
    localStorage.setItem("language", languageAbbreviation)
    stateToClose(false)

    // Reloading the page and scrollling to top on language change
    window.location.reload(true)
    window.scrollTo(0, 0)
  }

  // Router
  const location = useLocation()

  //Genel scroll fonksiyonu
  const scrollToRef = (url, referance) => {
    navigate(url)
    setTimeout(() => {
      let integrationDiv = document.getElementById(referance)
      if (integrationDiv) {
        window.scrollTo(0, integrationDiv.offsetTop)
      }
    }, 500)
  }

  //Bu array listeleri selected olacak olan menu itemin secili olarak gosterilmesi icin
  //kullanilacak
  const featureItemsSubPages = [
    "business-account",
    "company-cards",
    // "overview",
    "reimbursements",
    "pill-pay",
    "integrations",
  ]
  // const companyItemsSubPages = ["about-us", "blog", "careers", "contact-us"];
  const companyItemsSubPages = ["blog", "careers", "contact-us"]

  const expenseManagementSubPages = [
    {
      text: navbarData.creatingExpenses?.[currentLanguage],
      link: "/",
      key: "creatingExpenses",
      onClickEvent: () => scrollToRef("/", "expense-management-second-section"),
    },
    {
      text: navbarData.reviewingExpenses?.[currentLanguage],
      link: "/",
      key: "reviewingExpenses",
      onClickEvent: () => scrollToRef("/", "expense-management-third-section"),
    },
    {
      text: navbarData.expenseLimits?.[currentLanguage],
      link: "/",
      key: "expenseLimits",
      onClickEvent: () => scrollToRef("/", "expense-management-fourth-section"),
    },
    {
      text: navbarData.analytics?.[currentLanguage],
      link: "/",
      key: "analytics",
      onClickEvent: () => scrollToRef("/", "expense-management-sixth-section"),
    },
    {
      text: navbarData.integratedMessaging?.[currentLanguage],
      link: "/",
      kye: "integratedMessaging",
      onClickEvent: () => scrollToRef("/", "expense-management-fifth-section"),
    },
  ]

  const featureItems = [
    // {
    //   text: navbarData.overview?.[currentLanguage],
    //   subtext: navbarData.upcomingFeaturesOverview?.[currentLanguage],
    //   link: "/features/overview",
    //   key: "overview",
    // },
    {
      text: navbarData.businessAccount?.[currentLanguage],
      subtext: navbarData.depositTransfer?.[currentLanguage],
      link: "/features/business-account",
      key: "business-account",
    },
    {
      text: navbarData.companyCards?.[currentLanguage],
      subtext: navbarData.acceptedEverywhere?.[currentLanguage],
      link: "/features/company-cards",
      key: "company-cards",
    },
    {
      text: navbarData.reimbursements?.[currentLanguage],
      subtext: navbarData.oneClick?.[currentLanguage],
      link: "/features/reimbursements",
      key: "reimbursements",
    },
    {
      text: navbarData.billPay?.[currentLanguage],
      subtext: navbarData.billsInvoices?.[currentLanguage],
      link: "/features/bill-pay",
      key: "bill-pay",
    },
  ]

  const companyItems = [
    // {
    //   text: navbarData.aboutMuni?.[currentLanguage],
    //   subtext: navbarData.readStory?.[currentLanguage],
    //   link: "/company/about-us",
    //   key: "about-us",
    // },
    {
      text: navbarData.ourBlog?.[currentLanguage],
      subtext: navbarData.readOurWork?.[currentLanguage],
      link: "/company/blog",
      key: "blog",
    },
    {
      text: navbarData.workWithUs?.[currentLanguage],
      subtext: navbarData.joinRevolution?.[currentLanguage],
      link: "/company/careers",
      key: "careers",
    },
    {
      text: navbarData.contactUs?.[currentLanguage],
      subtext: navbarData.questionsSuggestions?.[currentLanguage],
      link: "/company/contact-us",
      key: "contact-us",
    },
  ]

  // const languages = [
  //   {
  //     text: "Türkçe",
  //     localized: "tr",
  //   },
  //   {
  //     text: "English",
  //     localized: "en",
  //   },
  // ]

  const navDropdown = (items, setStateFunction) => (
    <div
      className="nav_item_dropdown"
      onMouseEnter={() => setStateFunction(true)}
      onMouseLeave={() => setStateFunction(false)}
    >
      {items.map((item, index) => (
        <div
          key={index}
          className={`${
            currentSubPage === item.key ? "selected_navbar_item" : ""
          }`}
          onClick={() => {
            setCurrentSubPage(item.key)

            // Navigating to top of the page

            //Eger secili olan alt menu ogesi item.key'den farkli ise
            // ve yeni click edilen itemin item.keyi integrations ise
            //sayfa once navigate edilecek. Ardindan da referansi
            //kullanarak scroll edilmesi saglanacak. Timeout koyulmadiginda
            //henuz gecis tamamlanmadan referansa dogru hareket etmeye
            //calistigi icin hata aliyoruz. Bu nedenle timeout kullanilmak
            //zorunda. Else if durumunda ise kullanici zaten landing
            //page uzerinde oldugu icin direkt olarak referansa dogru
            //bir kayma hareketi yapilmaktadir.
            if (item.key === "integrations" && currentSubPage !== item.key) {
              navigate(`/features/overview/?lang=tr`)
              setTimeout(() => {
                item.onClickEvent()
              }, 200)
            } else if (item.key === "integrations") {
              item.onClickEvent()
            } else {
              navigate(`${item.link}/?lang=${currentLanguage}`)
              window.scrollTo(0, 0)
            }

            //Eger uzerine tiklanan subpagelerden birisi feature items
            // listesi icinde ise feature butonu secili hale gelecek
            if (featureItemsSubPages.includes(item?.key)) {
              setCurrentPage("features")
            } else if (companyItemsSubPages.includes(item?.key)) {
              setCurrentPage("company")
            }
          }}
        >
          <span>{item.text}</span>
          <span>{item.subtext}</span>
        </div>
      ))}
    </div>
  )

  const languageDropDown = (items, setStateFunction) => (
    <div
      className="nav_item_dropdown"
      style={{
        padding: 0,
      }}
      id="language-drop-down"
      onMouseEnter={() => setStateFunction(true)}
      onMouseLeave={() => setStateFunction(false)}
    >
      <span>{navbarData.language?.[currentLanguage]}</span>
      {items.map((item, index) => (
        <div
          key={index}
          onClick={() => {
            changeAppLanguage(item.key, setStateFunction)
          }}
        >
          <h4 className={`${item.key === currentLanguage ? "bold_text" : ""}`}>
            {item.language}
          </h4>
        </div>
      ))}
    </div>
  )

  const changeCompanyLanguage = companyLanguage => {
    // If the user does not already have a language,
    // then we set it according to the browser's language
    localStorage.setItem("language", companyLanguage.key)
    if (companyLanguage.key === localStorage.getItem("language")) {
      setCompanyLanguage(companyLanguage.key)
      dispatch(changeLanguage(companyLanguage.key))
    }
    // let browserLanguage = window.navigator.language
    // const langArray = browserLanguage.split("-")[0]
    // if (!currentLanguage) {
    //   if (langArray !== "tr") {
    //     localStorage.setItem("language", "en")
    //     dispatch(changeLanguage("en"))
    //     setCompanyLanguage("en")
    //   } else {
    //     localStorage.setItem("language", "tr")
    //     dispatch(changeLanguage("tr"))
    //     setCompanyLanguage("tr")
    //   }
    // } else {
    //   dispatch(changeLanguage(currentLanguage))
    //   setCompanyLanguage(currentLanguage)
    // }
  }

  // Dropdown States
  const [featuresDropdownStatus, setFeaturesDropdownStatus] = useState(false)
  const [companyDropdownStatus, setCompanyDropdownStatus] = useState(false)
  const [languageDropdownStatus, setLanguageDropdownStatus] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  // const [featuresMenuOpen, setFeaturesMenuOpen] = useState(false)

  const [pageScrolled, setPageScrolled] = useState(false)

  // Current Page
  const [currentPage, setCurrentPage] = useState("")
  const [currentSubPage, setCurrentSubPage] = useState("")

  /* USE EFFECTS */

  useEffect(() => {
    window.onscroll = () => {
      if (window.scrollY !== 0) {
        setPageScrolled(true)
      } else {
        setPageScrolled(false)
      }
    }
  }, [])

  // Checking what page we are on
  useEffect(() => {
    let currentLocations = location.pathname
      .split("/")
      .filter(item => item.length > 0)
    if (currentLocations.length === 0) {
      setCurrentPage("expenseManagement")
    } else {
      setCurrentPage(currentLocations[0])
      setCurrentSubPage(currentLocations[1])
    }
  }, [location])

  useEffect(() => {
    if (typeof window !== "undefined" ? localStorage.getItem("language") === "tr" : false) {
      setCompanyLanguage({
        language: "Türkçe",
        key: "tr",
      })
    } else {
      setCompanyLanguage({
        language: "English",
        key: "en",
      })
    }
  }, [currentLanguage])

  /* */

  return (
    <div className={`website_navbar ${pageScrolled ? "navbar_scrolled" : ""}`}>
      <div
        className="website_navbar_left"
        onClick={() => {
          setIsMenuOpen(false)
          navigate(`/?lang=${currentLanguage}`)
          // Navigating to top of the page
          window.scrollTo(0, 0)
        }}
      >
        <span>PENY</span>
      </div>
      <div className="website_navbar_middle">
        <span
          onClick={() => {
            navigate(`/pricing/?lang=${currentLanguage}`)

            // Navigating to top of the page
            window.scrollTo(0, 0)
          }}
          className={`nav_item ${
            currentPage === "pricing" ? "nav_item_active" : ""
          }`}
        >
          {navbarData.pricing?.[currentLanguage]}
        </span>
        <OverlayTrigger
          placement="bottom-start"
          overlay={navDropdown(featureItems, setFeaturesDropdownStatus)}
          show={featuresDropdownStatus}
          onToggle={e => {
            if (e) {
              setFeaturesDropdownStatus(true)
            } else {
              setFeaturesDropdownStatus(false)
            }
          }}
        >
          <span
            className={`nav_item ${
              currentPage === "features" &&
              featureItemsSubPages.includes(currentSubPage)
                ? "nav_item_active"
                : ""
            }`}
          >
            {navbarData.upcomingFeatures?.[currentLanguage]}
          </span>
        </OverlayTrigger>
        <OverlayTrigger
          placement="bottom-start"
          overlay={navDropdown(companyItems, setCompanyDropdownStatus)}
          show={companyDropdownStatus}
          onToggle={e => {
            if (e) {
              setCompanyDropdownStatus(true)
            } else {
              setCompanyDropdownStatus(false)
            }
          }}
        >
          <span
            className={`nav_item ${
              currentPage === "company" &&
              companyItemsSubPages.includes(currentSubPage)
                ? "nav_item_active"
                : ""
            }`}
          >
            {navbarData.company?.[currentLanguage]}
          </span>
        </OverlayTrigger>
      </div>
      <div className="website_navbar_right">
        <span className="nav_item">
          <a
            target="_blank"
            href="https://app.muni.com.tr"
            rel="noreferrer"
            className="non_styled_link"
          >
            {buttonsData.login?.[currentLanguage]}
          </a>
        </span>
        <Link
          to="https://app.muni.com.tr/signup"
          target="_blank"
          type="button"
          className="website_btn signup_btn d-none d-md-block text non_styled_link text-white"
        >
          {buttonsData.signUp?.[currentLanguage]}
        </Link>
        <OverlayTrigger
          placement="bottom-end"
          overlay={languageDropDown(languages, setLanguageDropdownStatus)}
          show={languageDropdownStatus}
          onToggle={e => {
            if (e) {
              setLanguageDropdownStatus(true)
            } else {
              setLanguageDropdownStatus(false)
            }
          }}
        >
          <div className="change_language_container">
            {/* <span
              className={`nav_item ${
                currentPage === "company" ? "nav_item_active" : ""
              }`}
            >
              {currentLanguage === "en" ? "EN" : "TR"}
            </span> */}
            <img
              className="website_footer_social_icon"
              src={languageIcon}
              alt="language icon"
            />
          </div>
        </OverlayTrigger>
      </div>

      <div
        id="mobile-menu-button"
        onClick={() => {
          setIsMenuOpen(!isMenuOpen)
        }}
      >
        <div
          id="mobile-menu-button-burger"
          className={isMenuOpen ? "open" : ""}
        ></div>
      </div>
      <div className={isMenuOpen ? "open_menu" : "hiddenMenu"}>
        <div className="open_menu_top">
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header
                bsPrefix={`accordion-header ${
                  currentPage === "expenseManagement"
                    ? "accordion_header_active"
                    : ""
                }`}
              >
                {navbarData.expenseManagement?.[currentLanguage]}
              </Accordion.Header>
              <Accordion.Body>
                <div className="nav_flex_column">
                  {expenseManagementSubPages.map((item, index) => {
                    return (
                      <span
                        key={index}
                        onClick={() => {
                          setIsMenuOpen(false)
                          navigate(`${item.link}?lang=${currentLanguage}`)
                          item.onClickEvent()
                          setCurrentSubPage(item.key)
                        }}
                        className={`nav_item ${
                          currentPage === "expenseManagement" &&
                          currentSubPage === item.key
                            ? "nav_item_active"
                            : ""
                        }`}
                      >
                        {item.text}
                      </span>
                    )
                  })}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header
                bsPrefix={`accordion-header pricing_accordion ${
                  currentPage === "pricing" ? "accordion_header_active" : ""
                }`}
                onClick={() => {
                  setIsMenuOpen(false)
                  navigate(`/pricing/?lang=${currentLanguage}`)

                  // Navigating to top of the page
                  window.scrollTo(0, 0)
                }}
              >
                {navbarData.pricing?.[currentLanguage]}
              </Accordion.Header>
            </Accordion.Item>
          </Accordion>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header
                bsPrefix={`accordion-header ${
                  currentPage === "features" ? "accordion_header_active" : ""
                }`}
              >
                {navbarData.upcomingFeatures?.[currentLanguage]}
              </Accordion.Header>
              <Accordion.Body>
                <div className="nav_flex_column">
                  {featureItems.map((item, index) => {
                    return (
                      <span
                        key={index}
                        onClick={() => {
                          setIsMenuOpen(false)
                          navigate(`${item.link}/?lang=${currentLanguage}`)

                          if (item.key !== "integrations") {
                            // Navigating to top of the page
                            window.scrollTo(0, 0)
                          } else {
                            item.onClickEvent()
                          }
                        }}
                        className={`nav_item ${
                          currentPage === "features" &&
                          currentSubPage === item.key
                            ? "nav_item_active"
                            : ""
                        }`}
                      >
                        {item.text}
                      </span>
                    )
                  })}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header
                bsPrefix={`accordion-header ${
                  currentPage === "company" ? "accordion_header_active" : ""
                }`}
              >
                {navbarData.company?.[currentLanguage]}
              </Accordion.Header>
              <Accordion.Body>
                <div className="nav_flex_column">
                  {companyItems.map((item, index) => {
                    return (
                      <span
                        key={index}
                        onClick={() => {
                          setIsMenuOpen(false)
                          navigate(`${item.link}/?lang=${currentLanguage}`)

                          // Navigating to top of the page
                          window.scrollTo(0, 0)
                        }}
                        className={`nav_item ${
                          currentPage === "company" &&
                          currentSubPage === item.key
                            ? "nav_item_active"
                            : ""
                        }`}
                      >
                        {item.text}
                      </span>
                    )
                  })}
                  <div></div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {/* <Accordion>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {navbarData.language?.[currentLanguage]}
              </Accordion.Header>
              <Accordion.Body>
                <div className="nav_flex_column">
                  <span
                    className={`nav_item ${
                      currentLanguage === "tr" ? "nav_item_active" : ""
                    }`}
                    onClick={() => {
                      changeAppLanguage("tr", setIsMenuOpen)
                    }}
                  >
                    {navbarData.turkish?.[currentLanguage]}
                  </span>
                  <span
                    className={`nav_item ${
                      currentLanguage === "en" ? "nav_item_active" : ""
                    }`}
                    onClick={() => {
                      changeAppLanguage("en", setIsMenuOpen)
                    }}
                  >
                    {navbarData.english?.[currentLanguage]}
                  </span>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion> */}
        </div>
        <div className="open_menu_bottom">
          <span className="nav_item">
            <a
              target="_blank"
              href="https://app.muni.com.tr"
              rel="noreferrer"
              className="non_styled_link"
            >
              {buttonsData.login?.[currentLanguage]}
            </a>
          </span>
          <Link
            className="nav_item non_styled_link"
            target="_blank"
            to="https://app.muni.com.tr/signup"
          >
            {buttonsData.signUp?.[currentLanguage]}
          </Link>
          <select
            className="muni_select_box w-25 position-absolute bottom_3 right_3"
            value={JSON.stringify(companyLanguage)}
            onChange={e => changeCompanyLanguage(JSON.parse(e.target.value))}
            onBlur={e => {
              e.target.classList.remove("active_input")
            }}
            onFocus={e => {
              e.target.classList.add("active_input")
            }}
          >
            {languages.map(language => (
              <option
                {...(currentLanguage === language.key
                  ? { selected: true }
                  : {})}
                value={JSON.stringify(language)}
              >
                {language.language}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  )
}

export default Navbar
