/* eslint-disable no-unused-vars */
import React from "react"

import {navigate} from "gatsby"

// Gatsby Image
import { StaticImage } from "gatsby-plugin-image"

//Redux
import { useSelector } from "react-redux"

//Asset import
import muniLogoWhite from "../../../assets/company/muni-logo-white.svg"
import linkedin from "../../../assets/icons/linkedin.svg"
import twitter from "../../../assets/icons/twitter.svg"
import facebook from "../../../assets/icons/facebook.svg"
import instagram from "../../../assets/icons/instagram.svg"

//Localized Data import
import localizedData from "../../../localized/navbarLocalized.json"

//components
import AccordionFooter from "./AccordionFooter"

import {
  footerCompanyItems,
  footerResourcesItems,
} from "../../../utilities/footerItems"

const Footer = () => {
  //Language information
  const currentLanguage = useSelector(state => state.changeLanguageReducer)


  const getGooglePlayBadge = () => {
    if (currentLanguage === "tr") {
      return (
        <StaticImage
          alt="Google Play Download"
          src="../../../images/store-badges/google-play-badge-TR.png"
          height={50}
          placeholder="blurred"
        />
      )
    } else {
      return (
        <StaticImage
          alt="Google Play Download"
          src="../../../images/store-badges/google-play-badge-EN.png"
          height={50}
          placeholder="blurred"
        />
      )
    }
  }
  const getAppStoreBadge = () => {
    if (currentLanguage === "tr") {
      return (
        <StaticImage
          alt="Google Play Download"
          src="../../../images/store-badges/app-store-badge-TR.png"
          height={50}
          placeholder="blurred"
        />
      )
    } else {
      return (
        <StaticImage
          alt="Google Play Download"
          src="../../../images/store-badges/app-store-badge-EN.png"
          height={50}
          placeholder="blurred"
        />
      )
    }
  }

  // useEffect(() => {
  //   setCurrentLanguage(localStorage.getItem('language'))
  // }, [currentLanguage])

  return (
    <div className="website_footer">
      <div className="website_footer_logo_container">
        <div className="website_footer_icon_and_name">
          <img
            src={muniLogoWhite}
            className="website_footer_logo"
            alt="Peny Logo"
          />
          <h1 className="website_footer_logo_title">PENY</h1>
        </div>
        <div className="website_footer_row">
          {/* {footerIcons.map((icon, index) => (
            <a key={index} target="_blank" rel="noreferrer" href={icon.href}>
              <img
                src={icon.src}
                className={`website_footer_icon ${
                  icon.alt === "Facebook logo" ?? "website_footer_no_margin"
                }`}
                alt={icon.alt}
              />
            </a>
          ))} */}
          <a
            target="_blank"
            href="https://www.linkedin.com/company/usemuni"
            rel="noreferrer"
          >
            <img
              src={linkedin}
              className="website_footer_social_icon"
              alt="Linkedin Logo"
            />
          </a>
          <a
            target="_blank"
            href="https://www.instagram.com/muni.app/"
            rel="noreferrer"
          >
            <img
              src={instagram}
              className="website_footer_social_icon"
              alt="Instagram Logo"
            />
          </a>
          <a
            target="_blank"
            href="https://twitter.com/usemuni"
            rel="noreferrer"
          >
            <img
              src={twitter}
              className="website_footer_social_icon"
              alt="Twitter Logo"
            />
          </a>
          <a
            target="_blank"
            href="https://www.facebook.com/usemuni"
            rel="noreferrer"
          >
            <img
              src={facebook}
              className="website_footer_social_icon website_footer_no_margin"
              alt="Facebook logo"
            />
          </a>
        </div>
      </div>
      {/* <div className="website_footer_selection_container d-none d-md-block">
        <h2 className="website_footer_title">
          {localizedData.product?.[currentLanguage]}
        </h2>
        {footerProductItems(currentLanguage).map((item, index) => {
          return (
            <h3
              key={index}
              onClick={() => {
                // Navigating to top of the page
                  navigate(`${item.link}/?lang=${currentLanguage}`)
                  window.scrollTo(0, 0)
              }}
              className="website_footer_element"
            >
              {item.title}
            </h3>
          )
        })}
      </div> */}
      <div className="website_footer_selection_container d-none d-md-block">
        <h2 className="website_footer_title">
          {localizedData.company?.[currentLanguage]}
        </h2>
        {footerCompanyItems(currentLanguage).map((item, index) => {
          return (
            <h3
              key={index}
              onClick={() => {
                navigate(`${item.link}/?lang=${currentLanguage}`)
                window.scrollTo(0, 0)
              }}
              className="website_footer_element"
            >
              {item.title}
            </h3>
          )
        })}
      </div>
      <div className="website_footer_selection_container d-none d-md-block">
        <h2 className="website_footer_title">
          {localizedData.resources?.[currentLanguage]}
        </h2>
        {footerResourcesItems(currentLanguage).map((item, index) => {
          return (
            <h3
              key={index}
              className="website_footer_element"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                })
                navigate(`${item.link}/?lang=${currentLanguage}`)
              }}
            >
              {item.title}
            </h3>
          )
        })}
      </div>
      <AccordionFooter/>
      {/* App Store Icons */}
      <a
        target="_blank"
        href="https://play.google.com/store/apps/details?id=com.muni.mobile"
        rel="noreferrer"
        id="footer-google-play-link"
      >
        {getGooglePlayBadge()}
      </a>
      <a
        target="_blank"
        href="https://apps.apple.com/us/app/muni/id1599044212"
        rel="noreferrer"
        id="footer-app-store-link"
      >
        {getAppStoreBadge()}
      </a>
    </div>
  )
}

export default Footer
