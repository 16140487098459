import React from "react"

import { navigate } from "gatsby"

//Redux
import { useSelector } from "react-redux"

//React bootstrap
import { Accordion } from "react-bootstrap"

import localizedData from "../../../localized/navbarLocalized.json"

//Muni items
import {
  footerCompanyItems,
  footerResourcesItems,
} from "../../../utilities/footerItems"

const AccordionFooter = () => {
  const currentLanguage = useSelector(state => state.changeLanguageReducer)

  return (
    <div className="w-100 d-block d-md-none" id="accordion-footer-container">
      {/* <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {localizedData.product?.[currentLanguage]}
          </Accordion.Header>
          <Accordion.Body>
            <div className="nav_flex_column">
              {footerProductItems(currentLanguage).map((item, index) => {
                return (
                  <h3
                    onClick={() => {
                      navigate(`${item.link}/?lang=${currentLanguage}`);
                      window.scrollTo(0, 0);
                    }}
                    key={index}
                    className="website_footer_element"
                  >
                    {item.title}
                  </h3>
                );
              })}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion> */}
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {localizedData.company?.[currentLanguage]}
          </Accordion.Header>
          <Accordion.Body>
            <div className="nav_flex_column">
              {footerCompanyItems(currentLanguage).map((item, index) => {
                return (
                  <h3
                    onClick={() => {
                      navigate(`${item.link}/?lang=${currentLanguage}`)
                      window.scrollTo(0, 0)
                    }}
                    key={index}
                    className="website_footer_element"
                  >
                    {item.title}
                  </h3>
                )
              })}
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Accordion>
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            {localizedData.resources?.[currentLanguage]}
          </Accordion.Header>
          <Accordion.Body>
            <div className="nav_flex_column">
              {footerResourcesItems(currentLanguage).map((item, index) => {
                return (
                  <h3
                    onClick={() => {
                      navigate(`${item.link}/?lang=${currentLanguage}`)
                      window.scrollTo(0, 0)
                    }}
                    key={index}
                    className="website_footer_element"
                  >
                    {item.title}
                  </h3>
                )
              })}
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.muni.mobile"
                rel="noreferrer"
                className="website_footer_element non_styled_link d_sm_none"
              >
                App Store
              </a>
              <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.muni.mobile"
                rel="noreferrer"
                className="website_footer_element non_styled_link d_sm_none"
              >
                Google Play Store
              </a>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  )
}

export default AccordionFooter
