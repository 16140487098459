import localizedData from '../localized/navbarLocalized.json'

export const footerProductItems = (currentLanguage) => {
  return [
    {
      title: localizedData?.businessAccount?.[currentLanguage],
      link: '/features/business-account'
    },
    {
      title: localizedData?.companyCards?.[currentLanguage],
      link: '/features/company-cards'
    },
    {
      title: localizedData?.expenseManagement?.[currentLanguage],
      link: '/features/expense-management'
    },
    {
      title: localizedData?.reimbursements?.[currentLanguage],
      link: '/features/reimbursements'
    },
    {
      title: localizedData?.billPay?.[currentLanguage],
      link: '/features/bill-pay'
    }
  ]
}

export const footerCompanyItems = (currentLanguage) => {
  return [
    // {
     //   title: localizedData.aboutMuni?.[currentLanguage],
    //   link: '/company/about-us'
    // },
    {
      title: localizedData.ourBlog?.[currentLanguage],
      link: '/company/blog',
    },
    {
      title: localizedData.workWithUs?.[currentLanguage],
      link: '/company/careers',
    },
    {
      title: localizedData.contactUs?.[currentLanguage],
      link: '/company/contact-us'
    }
  ]
}

export const footerResourcesItems = (currentLanguage) => {
  return [
    // {
    //   title: localizedData?.help?.[currentLanguage],
    //   link: ""
    // },
    {
      title: localizedData?.agreementsAndPolicies?.[currentLanguage],
      link: '/legal'
    }
  ]
}
